import React from 'react'
import Image4 from '../assets/Image4.jpg';
import Image5 from '../assets/Image5.jpg';
import Image8 from '../assets/Image8.jpg';
import Image9 from '../assets/Image9.jpg';
import Image10 from '../assets/Image10.jpg';
import Image11 from '../assets/Image11.PNG';
import Image12 from '../assets/Image12.PNG';
import Image13 from '../assets/Image13.PNG';
import Image14 from '../assets/Image14.PNG';


function Gallery() {
  return (
    <div className='grid grid-cols-2 md:grid-cols-3 gap-4 m-8'>
        
        
        <div className="flex md:w-96 h-auto " >
        <img src={Image10} alt="" />
        </div>
        <div className="flex md:w-96 h-auto " >
        <img src={Image9} alt="" />
        </div>
      
        <div className="flex md:w-96 h-auto " >
        <img src={Image5} alt="" />
        </div>
        <div className="flex md:w-96 h-auto " >
        <img src={Image4} alt="" />
        </div>
       
        <div className="flex md:w-96 h-auto " >
        <img src={Image8} alt="" />
        </div>
       
        <div className="flex md:w-96 h-auto " >
        <img src={Image11} alt="" />
        </div>
        <div className="flex md:w-96 h-auto " >
        <img src={Image12} alt="" />
        </div>
        <div className="flex md:w-96 h-auto " >
        <img src={Image13} alt="" />
        </div>
        <div className="flex md:w-96 h-auto " >
        <img src={Image14} alt="" />
        </div>
    </div>
  )
}

export default Gallery